@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
/* oswald-300 - latin */

@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/oswald-v36-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/oswald-v36-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/oswald-v36-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/oswald-v36-latin-300.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/oswald-v36-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/oswald-v36-latin-300.svg#Oswald') format('svg'); /* Legacy iOS */
  }

  /* oswald-regular - latin */
  @font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/oswald-v36-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/oswald-v36-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/oswald-v36-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/oswald-v36-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/oswald-v36-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/oswald-v36-latin-regular.svg#Oswald') format('svg'); /* Legacy iOS */
}