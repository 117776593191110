@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
/* oswald-300 - latin */

@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 300;
    src: url(/static/media/oswald-v36-latin-300.8ba88fbe.eot); /* IE9 Compat Modes */
    src: local(''),
         url(/static/media/oswald-v36-latin-300.8ba88fbe.eot?#iefix) format('embedded-opentype'), 
         url(/static/media/oswald-v36-latin-300.6baac703.woff2) format('woff2'), 
         url(/static/media/oswald-v36-latin-300.6397ef09.woff) format('woff'), 
         url(/static/media/oswald-v36-latin-300.5dfef84b.ttf) format('truetype'), 
         url(/static/media/oswald-v36-latin-300.d52401ae.svg#Oswald) format('svg'); /* Legacy iOS */
  }

  /* oswald-regular - latin */
  @font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    src: url(/static/media/oswald-v36-latin-regular.6e41c761.eot); /* IE9 Compat Modes */
    src: local(''),
         url(/static/media/oswald-v36-latin-regular.6e41c761.eot?#iefix) format('embedded-opentype'), 
         url(/static/media/oswald-v36-latin-regular.5ee68289.woff2) format('woff2'), 
         url(/static/media/oswald-v36-latin-regular.3d1d485b.woff) format('woff'), 
         url(/static/media/oswald-v36-latin-regular.bd101345.ttf) format('truetype'), 
         url(/static/media/oswald-v36-latin-regular.998bcd1c.svg#Oswald) format('svg'); /* Legacy iOS */
}
